<template>
  <v-app>
    <fondo-custom></fondo-custom>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <v-container class="transition">
      <v-row justify="" class="mt-sm-0 mt-md-12 pa-6 pa-md-0">
        <h1 class="text-h1 darkprimary--text">
          {{ $t("more_info") }}
        </h1>
      </v-row>
      <v-row class="px-6 px-md-0">
        <p>{{ $t("more_info_subtitle") }}</p>
      </v-row>

      <!-- EXPANSION PANELS ROW-->
      <v-row class="pa-2 mt-8 sm-pa-0">
        <v-expansion-panels class="light-expansion-panels" flat>
          <v-expansion-panel
            v-for="faq in getFaqs"
            :key="faq.id"
            class="px-0 rounded-lg mb-4"
          >
            <v-expansion-panel-header
              class="rounded-lg py-4 justify-space-between border-1"
              ripple
            >
              <h3 class="primary--text font-weight-light text-h3 mb-n2">
                {{ faq[`title_${$i18n.locale}`] }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="rounded-xl px-8">
              {{ faq[`content_${$i18n.locale}`] }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      bottom
      temporary
      right
      hide-overlay
      width="500px"
    >
      <v-list nav dense rounded>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item v-for="faq in getFaqs" :key="faq.id">
            <v-list-item-icon>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ faq[`title_${$i18n.locale}`] }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block outlined flat color="red" @click.stop="drawer = !drawer">
            {{ $t("Close") }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import FondoCustom from "@/components/mylogy/FondoCustom.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    FondoCustom
  },
  computed: {
    ...mapGetters("faqs", ["getFaqs"])
  },
  methods: {
    goToExternal(externalURL) {
      window.open(externalURL, "_blank");
    },
    all() {
      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    }
  },
  data: () => ({
    expandOnHover: false,
    panel: [],
    items: 5,
    drawer: false
  }),
  mounted() {}
};
</script>

<style lang="scss" scoped>
.light-expansion-panels .v-expansion-panel {
  border: solid 1px #4d2cb960;
}
.content-index {
  transform: translateX(-0%);
  overflow: auto;
}
</style>
